<template>
  <div id="connectiveId">
    <div class="page-container-table">
      <!--phần header-->
      <button-all-header
        :contentBtnAdd="'Thêm đơn vị liên kết'"
        :arrayExcel="[]"
        :showBtnMultiDelete="showBtnMultiDelete"
        :hideImportFile="false"
        :hideDowload="false"
        :hideExportFile="false"
        @clickDelete="deleteItems"
        @clickAdd="showModalReport"
        @search="() => {}"
      />
      <!--Phần Bảng -->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataTable || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column:Mã đơn vị -->
          <span
            v-if="props.column.field === 'code'"
            class="text-nowrap"
          >
            <b-badge
              pill
              class="light-code"
            >
              <span class="text-name">{{ props.row.code }}</span>
            </b-badge>
          </span>
          <!-- Tên đơn vị -->
          <span v-if="props.column.field == 'name'">
            {{ props.row.name }}
          </span>
          <!-- URL -->
          <span v-if="props.column.field == 'url'">
            {{ props.row.url }}
          </span>
          <!-- Lãnh đạo -->
          <span v-if="props.column.field == 'numberOfLeader'">
            {{ props.row.numberOfLeader }}
          </span>
          <!-- Địa chỉ -->
          <span v-if="props.column.field == 'address'">
            {{ props.row.address }}
          </span>
          <!-- Trạng thái -->
          <span v-if="props.column.field == 'status'">
            <feather-icon
              v-if="props.row.status === 'open'"
              icon="CheckIcon"
              size="18"
              class="text-body"
              @click="showModalReport(props.row.id)"
            />
          </span>
          <!-- Chức năng -->
          <span v-if="props.column.field == 'action'">
            <span>
              <feather-icon
                icon="EditIcon"
                size="18"
                class="text-body"
                @click="showModalReport(props.row.id)"
              />
              <feather-icon
                icon="Trash2Icon"
                size="18"
                class="text-body ml-2"
                @click="deleteItem(props.row.id)"
              />
            </span>

          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="currentPage"
        @pageClick="() => {}"
      />

      <!-- modal xóa-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="() => {}"
      />

      <!--Tạo kiểm định viên-->
      <modal-create-connective
        :id="modalCreateReport"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalCreateConnective from './components/ModalCreateConnective.vue'
import fakeData from '../fakeData'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    ShowFile,
    ModalCreateConnective,
  },
  data() {
    return {
      totalRecord: 10,
      dataTable: fakeData.dataTableUnit,
      columns: [
        {
          label: 'MÃ ĐƠN VỊ',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN ĐƠN VỊ',
          field: 'name',
          sortable: false,
        },
        {
          label: 'URL',
          field: 'url',
          sortable: false,
        },
        {
          label: 'LÃNH ĐẠO',
          field: 'numberOfLeader',
          sortable: false,
        },
        {
          label: 'ĐỊA CHỈ',
          field: 'address',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI',
          field: 'status',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          sortable: false,
          width: '175px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      modalCreateReport: 'comfirm-create',
      deleteIds: [],
      showBtnMultiDelete: false,
    }
  },
  methods: {

    // Mở modal tạo và chỉnh sửa kiểm định viên
    showModalReport() {
      this.$bvModal.show(this.modalCreateReport)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },
  },
}
</script>

<style lang="scss">
#connectiveId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }
}
</style>
