export default {
  dataTable: [
    {
      id: 1,
      code: 'NV001',
      name: 'Khanh',
      account: 'Admin',
      position: 'Quản lý',
      unit: 'CÔNG TY TNHH MOZLEX VIỆT NAM',
      address: 'Số nhà 424, đường Phan Đình Phùng,, Phường Phan Đình Phùng, Thành phố Thái Nguyên, Thái Nguyên',
      status: 'open',
    },
    {
      id: 2,
      code: 'NV002',
      name: 'Hoàng',
      account: 'nhanvien',
      position: 'Nhân viên',
      unit: 'CÔNG TY TNHH PACKELZING VINA',
      address: 'Số 10, ngõ 231 đường Thống Nhất,, Phường Tân Lập, Thành phố Thái Nguyên, Thái Nguyên',
      status: 'open',
    },
    {
      id: 3,
      code: 'NV003',
      name: 'Khoa',
      account: 'nhanvien',
      position: 'Nhân viên',
      unit: 'CÔNG TY TNHH CÂU XANH VÀ MÔI TRƯỜNG KHẢI HÒA',
      address: 'Xóm 7,, Xã Sơn Cẩm, Thành phố Thái Nguyên, Thái Nguyên',
      status: 'open',
    },
  ],
  dataTableUnit: [
    {
      id: 1,
      code: 'EDM',
      name: 'Đơn vị huấn luyện cấp cứu CRS',
      url: 'thainguyen.office.vn',
      numberOfLeader: 1,
      address: 'Thái Nguyên',
      status: 'open',
    },
    {
      id: 2,
      code: 'VNPTBP_OFFICE',
      name: 'CÔNG TY CỔ PHẦN AROHA PHARMA',
      url: 'thainguyen.vn',
      numberOfLeader: 2,
      address: 'Xóm Ngọc Sơn, Xã Điềm Thụy, Huyện Phú Bình, Thái Nguyên',
      status: 'open',
    },
  ],
}
